import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  query me {
    me {
      guid
      firstName
      lastName
      fullName
      motherName
      birthPlace
      birthDate
      birthName
      preferredLanguage
      nationality
      gender
      memberCardStatusHelper
      myPrimaryMembercard {
        id
        barcode
        isPrimary
        registeredAt
        expirationDate
      }
      myPrimaryPhoneAddress {
        id
        isPrimary
        phoneNumber
      }
      myPrimaryEmailAddress {
        id
        isPrimary
        emailAddress
      }
      myPrimaryPhysicalAddress {
        id
        isPrimary
        country
        zipCode
        city
        addressLine
      }
      myResponsiblePatron {
        id
        username
        firstname
        lastname
        memberCards_v2 {
          id
          barcode
          isPrimary
        }
      }
      myResponsibleForPatrons {
        id
        username
        firstname
        lastname
        memberCards_v2 {
          id
          barcode
          isPrimary
        }
      }
    }
  }
`;
