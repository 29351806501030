import { gql } from '@apollo/client';

export const FETCH_LOAN_HISTORY = gql`
  query fetchLoanHistory($take: Int, $skip: Int) {
    me {
      guid
      myLoansHistory(take: $take, skip: $skip) {
        id
        loanDate
        expiryDate
        returnDate
        returned
        item {
          guid
          physicalManifestationOf {
            title
          }
        }
      }
    }
  }
`;

export const FETCH_LOAN_HISTROY_COUNT = gql`
  query fetchLoanHistoryCount {
    me {
      myLoansHistory_count
    }
  }
`;

export const FETCH_RESERVATION_HISTORY = gql`
  query me($take: Int, $skip: Int) {
    me {
      guid

      myReservationsHistory(take: $take, skip: $skip) {
        guid
        expirationDate
        expectedDeliveryDate
        status
        targetItem {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
      }
    }
  }
`;

export const FETCH_RESERVATION_HISTORY_COUNT = gql`
  query fetchReservationHistoryCount {
    me {
      myReservationsHistory_count
    }
  }
`;

export const FETCH_REQUEST_HISTORY = gql`
  query me($take: Int, $skip: Int) {
    me {
      guid

      myRequestsHistory(take: $take, skip: $skip) {
        id
        requestDate
        status
        bibliographicRecord {
          guid
          title
        }
        item {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
      }
    }
  }
`;

export const FETCH_REQUEST_HISTORY_COUNT = gql`
  query fetchRequestHistoryCount {
    me {
      guid

      myRequestsHistory_count
    }
  }
`;
