import { common } from '../../../constants/common.js';
import { useLazyQuery } from '@apollo/client';
import { ME_QUERY } from './me';
import Box from '@material-ui/core/Box';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from 'material-ui-flat-pagination';
// UTILS
import moment from 'moment';
// STYLE
import tableStyles from '../../HoldingsTable/HoldingsTable.module.scss';

const ProfileTabsFeesTab = props => {
  const [feeData, setFeeData] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [historyPage, setHistoryPage] = React.useState(0);
  const [take, setTake] = React.useState(10);

  const [getFeeData, { data }] = useLazyQuery(ME_QUERY, {
    variables: {
      take: take,
      skipAct: activePage * take,
      skipHis: historyPage * take,
      orderBy: {
        createdAt: 'desc',
      },
    },
    onCompleted: data => {
      setFeeData(data);
    },
  });

  const getNameSpace = value => {
    return common()?.[value] || value;
  };

  const activePaginationClickHandler = offset => {
    setActivePage(offset / 10);
  };

  const historyPaginationClickHandler = offset => {
    setHistoryPage(offset / 10);
  };

  React.useEffect(() => {
    getFeeData();
  }, [activePage, historyPage, take]);

  return (
    <>
      <Box className={[tableStyles.tableContainer].join(' ')}>
        <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
          <Typography variant={'h6'}>Aktuális díjak</Typography>
          <Table
            className={[
              tableStyles.instanceListTable,
              tableStyles.openTable,
            ].join(' ')}
          >
            <TableHead>
              <TableRow>
                <TableCell>Díjtétel típusa</TableCell>
                <TableCell>Létrehozás ideje</TableCell>
                <TableCell>Példány vonalkódja</TableCell>
                <TableCell>Státusz</TableCell>
                <TableCell>Hátramaradó összeg</TableCell>
                <TableCell>Teljes összeg</TableCell>
                <TableCell>Részletfizetés időpontja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeData?.me?.myActiveFees?.map(node => {
                return (
                  <TableRow key={node.id}>
                    <TableCell>{getNameSpace(node?.type)}</TableCell>
                    <TableCell>
                      {moment(node?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell>{node?.item?.barcode}</TableCell>
                    <TableCell>{getNameSpace(node?.status)}</TableCell>
                    <TableCell>{node?.currentAmount}</TableCell>
                    <TableCell>{node?.amount}</TableCell>
                    <TableCell>{node?.paidAt}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          offset={activePage * take}
          limit={take}
          total={Math.ceil(feeData?.me?.myActiveFees_count)}
          onClick={(e, offset) => activePaginationClickHandler(offset)}
          style={{ float: 'right' }}
        />
      </Box>
      <Box className={[tableStyles.tableContainer].join(' ')}>
        <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
          <Typography variant={'h6'}>Korábbi díjak</Typography>
          <Table
            className={[
              tableStyles.instanceListTable,
              tableStyles.openTable,
            ].join(' ')}
          >
            <TableHead>
              <TableRow>
                <TableCell>Díjtétel típusa</TableCell>
                <TableCell>Létrehozás ideje</TableCell>
                <TableCell>Példány vonalkódja</TableCell>
                <TableCell>Státusz</TableCell>
                <TableCell>Kifizetett összeg</TableCell>
                <TableCell>Kifizetés/Törlés dátuma</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeData?.me?.myFeeHistory?.map(node => {
                const payDate = node?.invalidatedAt
                  ? node?.invalidatedAt
                  : node?.paidAt;

                return (
                  <TableRow key={node.id}>
                    <TableCell>{getNameSpace(node?.type)}</TableCell>
                    <TableCell>
                      {moment(node?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell>{node?.item?.barcode}</TableCell>
                    <TableCell>{getNameSpace(node?.status)}</TableCell>
                    <TableCell>{node?.amount}</TableCell>
                    <TableCell>
                      {moment(payDate).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          offset={historyPage * take}
          limit={take}
          total={Math.ceil(feeData?.me?.myFeeHistory_count)}
          onClick={(e, offset) => historyPaginationClickHandler(offset)}
          style={{ float: 'right' }}
        />
      </Box>
    </>
  );
};

export default ProfileTabsFeesTab;
