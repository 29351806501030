import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core'
import { FETCH_LOAN_ZONES_COUNT_FOR_AUTOCOMPLETE, FETCH_LOAN_ZONES_FOR_AUTOCOMPLETE } from '../ProfileTabs/ProfileTabsActiveReservationsTab/me'
import React, { useState } from 'react'

import { useQuery } from '@apollo/client'

const ClaimRequestDialog = ({ open, closeDialog, handleSave }) => {
  const [selectedLoanZoneGuid, setSelectedLoanZoneGuid] = useState("");
  const [error, setError] = useState(null);
  const { data: countData } = useQuery(FETCH_LOAN_ZONES_COUNT_FOR_AUTOCOMPLETE);

  const { data } = useQuery(FETCH_LOAN_ZONES_FOR_AUTOCOMPLETE, {
    variables: {
      take: countData?.loanZones_count
    },
    skip: !Boolean(countData)
  })

  const selectOptions = (data?.loanZones || []).map(x => (
    <MenuItem key={x.guid} value={x.guid}>{x.publicName}</MenuItem>
  ))

  const onChange = e => {
    setSelectedLoanZoneGuid(e?.target?.value)
  }

  const onSave = () => {
    if(!selectedLoanZoneGuid) {
      setError("Kötelező mező!")
      return
    }
    setError(null)
    handleSave(selectedLoanZoneGuid)
    closeDialog()
  }

  const onClose = () => {
    closeDialog();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Félretétel</DialogTitle>
      <DialogContent>
        <TextField
          select
          name="loanZone"
          label="Kölcsönzési pont"
          value={selectedLoanZoneGuid}
          onChange={onChange}
          children={selectOptions}
          fullWidth
          variant="outlined"
          margin="dense"
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          size="small" 
          color="primary" 
          variant="outlined" 
          onClick={onClose}
        >Mégse</Button>
        <Button  
          size="small"
          color="primary"
          onClick={onSave}
          variant="outlined"
        >Mentés</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClaimRequestDialog