import { common } from '../../../constants/common.js';
import { useLazyQuery } from '@apollo/client';
import { ME_QUERY } from './me';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import TextFieldInput from '../../UI/TextFieldInput';
import SelectInputField from '../../UI/SelectInputField';
import MultiSelectInputField from '../../UI/MultiSelectInputField';

// UTILS
import moment from 'moment';
import { Button } from '@material-ui/core';
import { deploymentCode } from '../../../utils/featureControl.js';

const ProfileTabsReaderDataTab = (props) => {
  const [profilData, setProfilData] = React.useState({
    readerCardId: '',
    lastName: '',
    firstName: '',
    fullName: '',
    motherName: '',
    birthName: '',
    birthPlace: '',
    birthDate: '',
    language: '',
    citizenship: '',
    sex: '',
    country: '',
    postalCode: '',
    city: '',
    addressLine: '',
    phone: '',
    email: '',
    admissionDate: '',
    expirationDate: '',
    status: '',
    guaranteePerson: {
      id: '',
      firstname: '',
      lastname: '',
      memberCards_v2: {
        id: '',
        barcode: '',
        isPrimary: '',
      },
    },
    relatedPerson: [],
  });

  const getNameSpace = (value) => {
    return common()?.[value] || value;
  };

  const [getProfilData, { data }] = useLazyQuery(ME_QUERY, {
    onCompleted: (data) => {
      setProfilData({
        readerCardId: data?.me?.myPrimaryMembercard?.barcode || null,
        lastName: data?.me?.lastName || null,
        firstName: data?.me?.firstName || null,
        fullName: data?.me?.fullName || null,
        motherName: data?.me?.motherName || null,
        birthName: data?.me?.birthName || null,
        birthPlace: data?.me?.birthPlace || null,
        birthDate: data?.me?.birthDate || null,
        language: getNameSpace(data?.me?.preferredLanguage) || null,
        citizenship: getNameSpace(data?.me?.nationality) || null,
        sex: getNameSpace(data?.me?.gender) || null,
        country: data?.me?.myPrimaryPhysicalAddress?.country || null,
        postalCode: data?.me?.myPrimaryPhysicalAddress?.zipCode || null,
        city: data?.me?.myPrimaryPhysicalAddress?.city || null,
        addressLine: data?.me?.myPrimaryPhysicalAddress?.addressLine || null,
        phone: data?.me?.myPrimaryPhoneAddress?.phoneNumber || null,
        email: data?.me?.myPrimaryEmailAddress?.emailAddress || null,
        admissionDate: data?.me?.myPrimaryMembercard?.registeredAt || null,
        expirationDate: data?.me?.myPrimaryMembercard?.expirationDate || null,
        status: getNameSpace(data?.me?.memberCardStatusHelper) || null,
        guaranteePerson: data?.me?.myResponsiblePatron || {},
        relatedPerson: data?.me?.myResponsibleForPatrons || [],
      });
    },
  });

  useEffect(() => {
    getProfilData();
  }, []);

  const addressTypes = ['Állandó lakcím', 'Ideiglenes lakcím'];

  const [addressType, setAddressType] = React.useState('Állandó lakcím');
  const [readOnly] = React.useState(true);

  const setSelectData = (dataName, dataValue) => {
    setProfilData({
      ...profilData,
      [dataName]: dataValue,
    });
  };

  const setMultiSelectData = (name, dataValue) => {
    const multidata = profilData?.[name].filter((p) => dataValue.includes(p.id));
    setProfilData({
      ...profilData,
      [name]: multidata,
    });
  };

  const setAddressTypeHandler = (value) => {
    setAddressType(value);
    // setProfilData({
    //   ...profilData,
    //   country: data[type.guid]?.country || null,
    //   postalCode: data[type.guid]?.postalCode || null,
    //   city: data[type.guid]?.city || null,
    //   addressLine: data[type.guid]?.addressLine || null,
    // });
  };

  return (
    <Grid container spacing={2} className="profileDataContainer">
      <Grid item xs={12}>
        <Typography variant={'h6'}>Belépési adatok</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          name={'readerCardId'}
          value={profilData?.readerCardId}
          label={deploymentCode === 'isxn' ? 'Felhasználónév' : 'Olvasójegy száma'}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h6'}>Személyes adatok</Typography>
      </Grid>
      {/* <Grid item xs={6}>
        <TextFieldInput
          name={'lastName'}
          value={profilData?.lastName}
          label={'Vezetéknév'}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          name={'firstName'}
          value={profilData?.firstName}
          label={'Keresztnév'}
          disabled={readOnly}
        />
      </Grid> */}
      <Grid item xs={6}>
        <TextFieldInput
          name={'fullName'}
          value={
            profilData?.fullName ||
            [profilData?.lastName, profilData?.firstName].filter((x) => x).join(' ')
          }
          label={'Teljes név'}
          disabled={readOnly}
        />
      </Grid>
      {deploymentCode !== 'isxn' ? (
        <Grid item xs={6}>
          <TextFieldInput
            name={'motherName'}
            value={profilData?.motherName}
            label={'Anyja neve'}
            disabled={readOnly}
          />
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}
      {deploymentCode !== 'isxn' && (
        <>
          <Grid item xs={6}>
            <TextFieldInput
              name={'birthName'}
              value={profilData?.birthName}
              label={'Születési név'}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'birthPlace'}
              value={profilData?.birthPlace}
              label={'Születési hely'}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'birthDate'}
              value={moment(profilData?.birthDate).format('YYYY-MM-DD')}
              label={'Születési dátum'}
              type="date"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInputField
              name={'language'}
              value={profilData?.language}
              label={'Nyelv'}
              setData={setSelectData}
              options={[profilData?.language]}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInputField
              name={'citizenship'}
              value={profilData?.citizenship}
              label={'Állampolgárság'}
              setData={setSelectData}
              options={[profilData?.citizenship]}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInputField
              name={'sex'}
              value={profilData?.sex}
              label={'Nem'}
              setData={setSelectData}
              options={[profilData?.sex]}
              disabled={readOnly}
            />
          </Grid>
        </>
      )}
      {/* <Grid item xs={6}></Grid> */}
      <Grid item xs={2}>
        <Typography variant={'h6'}>Elérhetőség</Typography>
      </Grid>
      {deploymentCode !== 'isxn' ? (
        <Grid item xs={2}>
          <SelectInputField
            name={'addressType'}
            value={addressType}
            label={'Lakcím'}
            onChange={(e) => setAddressTypeHandler(e.target.value)}
            options={addressTypes}
            disabled={readOnly}
          />
        </Grid>
      ) : (
        <Grid item xs={2} />
      )}
      <Grid item xs={8}></Grid>
      {deploymentCode !== 'isxn' && (
        <>
          <Grid item xs={6}>
            <TextFieldInput
              name={'country'}
              value={profilData?.country}
              label={'Ország'}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'postalCode'}
              value={profilData?.postalCode}
              label={'Irányítószám'}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'city'}
              value={profilData?.city}
              label={'Város'}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'addressLine'}
              value={profilData?.addressLine}
              label={'Cím'}
              disabled={readOnly}
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <TextFieldInput
          name={'phone'}
          value={profilData?.phone}
          label={'Telefonszám'}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          name={'email'}
          value={profilData?.email}
          label={'E-mail cím'}
          disabled={readOnly}
        />
      </Grid>
      {deploymentCode !== 'isxn' && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Olvasószolgálattal kapcsolatos adatok</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'admissionDate'}
              value={moment(profilData?.admissionDate).format('YYYY-MM-DD')}
              label={'Beiratkozás dátuma'}
              type="date"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              name={'expirationDate'}
              value={moment(profilData?.expirationDate).format('YYYY-MM-DD')}
              label={'Lejárati dátum'}
              type="date"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInputField
              name={'status'}
              label={'Státusz'}
              setData={setSelectData}
              options={[profilData?.status]}
              disabled={readOnly}
              value={profilData?.status}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </>
      )}
      {deploymentCode !== 'isxn' && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Kapcsolódó profilok</Typography>
          </Grid>
          <Grid item xs={6}>
            <SelectInputField
              name={'guaranteePerson'}
              value={profilData?.guaranteePerson?.id}
              label={'Jótálló'}
              setData={setSelectData}
              options={[profilData?.guaranteePerson]}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <MultiSelectInputField
              name={'relatedPerson'}
              value={profilData?.relatedPerson?.map((p) => p.id) || []}
              label={'Hozzátartozó'}
              setData={setMultiSelectData}
              options={profilData?.relatedPerson}
              disabled={readOnly}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProfileTabsReaderDataTab;
