import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  query me {
    me {
      guid
      myActiveReservations(take: 100) {
        guid
        createdAt
        status
        expirationDate
        expectedDeliveryDate
        targetItem {
          guid
          physicalManifestationOf {
            title
          }
        }
        targetLoanZone {
          guid
          publicName
        }
      }
      myActiveRequests(take: 100) {
        id
        requestDate
        expiryDate
        status
        bibliographicRecord {
          guid
          title
        }
        item {
          guid
          physicalManifestationOf {
            title
          }
        }
      }
      myActiveLoans(take: 100) {
        id
        loanDate
        expiryDate
        item {
          guid
          barcode
          physicalManifestationOf {
            guid
            title
          }
        }
      }
    }
  }
`;

export const FETCH_LOAN_ZONES_FOR_AUTOCOMPLETE = gql`
  query fetchLoanZonesForAutocomplete(
    $take: Int
    $skip: Int
    $orderBy: [LoanZoneOrderByWithRelationInput]
  ) {
    loanZones(take: $take, skip: $skip, orderBy: $orderBy) {
      guid
      publicName
    }
  }
`;

export const FETCH_LOAN_ZONES_COUNT_FOR_AUTOCOMPLETE = gql`
  query fetchLoanZoneCountForAutocomplete {
    loanZones_count
  }
`;
