import React from 'react';
import { resolveStatusColumn } from '../../../utils/transaction';
import Box from '@material-ui/core/Box';
import CancelRequestButton from '../../HoldingsTable/HoldingsTableOperationColumn/CancelRequestButton/CancelRequestButton';
import CancelReservationButton from '../../HoldingsTable/HoldingsTableOperationColumn/CancelReservationButton/CancelReservationButton';
import ClaimRequestButton from '../../ClaimRequestButton/ClaimRequestButton';
import ClaimRequestDialog from '../../ClaimRequestDialog/ClaimRequestDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useExtendLoan } from '../../../hooks/useExtendLoan';
// UTILS
import moment from 'moment';
import { useClaimRequestDialogControl } from '../../../hooks/useClaimRequestDialogcontrol';
// STYLE
import tableStyles from '../../HoldingsTable/HoldingsTable.module.scss';
import { Button, Typography } from '@material-ui/core';
import { userInfoVar } from '../../../apollo/localState/userInfoVar';
import { useReactiveVar } from '@apollo/client';
import { isExtendButtonDisabledByDate } from '../../../utils/common';

const ProfileTabsActiveReservationsTabTable = ({ tableData, type, title }) => {
  const { isMemberCardActive } = useReactiveVar(userInfoVar);
  const { extendLoan, loading: loanExtensionInProgress } = useExtendLoan();

  const {
    openClaimRequestDialog,
    props: claimRequestDialogProps,
  } = useClaimRequestDialogControl();

  const isLoan = () => type === 'myActiveLoans';
  const isReservation = () => type === 'myActiveReservations';
  const isRequest = () => type === 'myActiveRequests';

  const handleExtendLoan = node => () => {
    if (loanExtensionInProgress || !isMemberCardActive) return;

    extendLoan(node);
  };

  return (
    <Box className={[tableStyles.tableContainer].join(' ')} marginTop={'50px'}>
      <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
        <Typography variant="h6">{title}</Typography>
        <Table
          stickyHeader
          className={[
            tableStyles.instanceListTable,
            tableStyles.openTable,
          ].join(' ')}
        >
          <TableHead>
            <TableRow>
              <TableCell>Cím</TableCell>
              <TableCell>{`${title} ideje`}</TableCell>
              {!isRequest() && <TableCell>Esedékesség</TableCell>}
              {/* {isReservation() && <TableCell>Kölcsönzési pont</TableCell>} */}
              {!isLoan() && <TableCell>Státusz</TableCell>}
              {!isLoan() && <TableCell>Műveletek</TableCell>}
              {isLoan() && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData[type]?.map(node => (
              <TableRow key={node.guid || node.id}>
                <TableCell>
                  {
                    (
                      (node?.targetItem || node?.item)
                        ?.physicalManifestationOf || node?.bibliographicRecord
                    )?.title
                  }
                </TableCell>
                <TableCell>
                  {moment(node.dateForSort).format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                {!isRequest() && (
                  <TableCell>
                    {(node?.expectedDeliveryDate || node?.expiryDate) &&
                      moment(
                        node?.expectedDeliveryDate || node?.expiryDate
                      ).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                )}
                {/* {isReservation() && (
                  <TableCell>{node?.targetLoanZone?.publicName}</TableCell>
                )} */}
                {!isLoan() && (
                  <TableCell>
                    {resolveStatusColumn(node?.status || node?.returned)}
                  </TableCell>
                )}
                {!isLoan() && (
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                      }}
                    >
                      {node?.status === 'AwaitingResponse' && (
                        <ClaimRequestButton
                          guid={node?.id}
                          openClaimRequestDialog={openClaimRequestDialog}
                        />
                      )}
                      {node?.__typename === 'Request' && (
                        <CancelRequestButton guid={node?.id} />
                      )}
                      {node?.__typename === 'Reservation' && (
                        <CancelReservationButton guid={node?.guid} />
                      )}
                    </div>
                  </TableCell>
                )}
                {isLoan() && (
                  <TableCell>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={handleExtendLoan(node)}
                      disabled={
                        loanExtensionInProgress ||
                        !isMemberCardActive ||
                        isExtendButtonDisabledByDate(node.expiryDate, 5)
                      }
                    >
                      Hosszabbítás
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ClaimRequestDialog {...claimRequestDialogProps} />
      </Box>
    </Box>
  );
};

export default ProfileTabsActiveReservationsTabTable;
