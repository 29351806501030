export const translateReservationStatus = (status) => {
  const statuses = {
    Completed: 'Teljesített',
    Canceled: 'Visszavont',
    Expired: 'Lejárt',
    Available: 'Elérhető',
    Failed: 'Meghiusúlt'
  }

  return statuses[status] ?? ''
}

export const translateRequestStatus = (status) => {
  const statuses = {
    Completed: 'Teljesített',
    Canceled: 'Visszavont',
    Expired: 'Lejárt',
    Available: 'Elérhető',
    AwaitingResponse: 'Válaszra vár',
    Failed: 'Meghiusúlt'
  }

  return statuses[status] ?? ''
}