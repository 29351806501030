import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const SelectInputField = ({
  name,
  label,
  value,
  disabled = false,
  setData,
  options = [],
  onChange,
}) => {
  const handleChange = event => {
    setData(name, event?.target?.value);
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth disabled={disabled}>
      <InputLabel id={`${name}Label`}>{label}</InputLabel>
      <Select
        value={value}
        labelId={`${name}Label`}
        label={label}
        name={name}
        onChange={onChange ? onChange : handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map(option => (
          <MenuItem key={option?.id || option} value={option?.id || option}>
            {option?.memberCards_v2
              ? `${option?.memberCards_v2?.[0]?.barcode}: ${option?.lastname} ${option?.firstname}`
              : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInputField;
