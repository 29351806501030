import {
  gql,
  useApolloClient,
  useMutation,
  useReactiveVar,
} from '@apollo/client';

import { Button } from '@material-ui/core';
import { CLAIM_AND_CONVERT_REQUEST_TO_RESERVATION } from '../HoldingsTable/HoldingsTableOperationColumn/mutation';
import React from 'react';
import { withSnackbar } from 'notistack';
import { userInfoVar } from '../../apollo/localState/userInfoVar';

const ClaimRequestButton = ({
  guid,
  openClaimRequestDialog,
  enqueueSnackbar,
}) => {
  const client = useApolloClient();
  const { isMemberCardActive } = useReactiveVar(userInfoVar);

  const [claim, { loading }] = useMutation(
    CLAIM_AND_CONVERT_REQUEST_TO_RESERVATION,
    {
      onCompleted() {
        enqueueSnackbar('Félretétel sikeres!', {
          variant: 'success',
        });
      },
      onError(error) {
        enqueueSnackbar('Félretétel sikertelen!', {
          variant: 'error',
        });
      },
      refetchQueries: [
        'searchBibItems',
        'fetchMyTransactions',
        'fetchMyTransactionsCount',
      ],
    }
  );

  const onSave = loanZoneGuid => {
    if (loading || !isMemberCardActive) return;

    claim({
      variables: {
        where: {
          id: guid,
        },
        data: {
          targetLoanZone: {
            connect: {
              guid: loanZoneGuid,
            },
          },
        },
      },
    });
  };

  const onClick = async () => {
    if (loading || !isMemberCardActive) return;

    const { data: dataCount } = await client.query({
      query: gql`
        query fecthLoanZoneCount {
          loanZones_count
        }
      `,
    });

    const count = dataCount?.loanZones_count;

    if (count < 1) {
      return;
    }

    if (count === 1) {
      const { data: loanZoneData } = await client.query({
        query: gql`
          query fetchLoanZones {
            loanZones {
              guid
            }
          }
        `,
      });

      onSave(loanZoneData?.loanZones?.[0]?.guid);
    } else {
      openClaimRequestDialog({
        guid,
        handleSave: targetLoanZoneGuid => {
          onSave(targetLoanZoneGuid);
        },
      });
    }
  };

  return (
    <Button
      size="small"
      color="primary"
      onClick={onClick}
      variant="outlined"
      disabled={loading || !isMemberCardActive}
    >
      Félretétel
    </Button>
  );
};

export default withSnackbar(ClaimRequestButton);
