import React, { useContext, useEffect } from 'react';
//COMPONENTS
import Head from '../components/head';
import SubPageLayout from '../layouts/subpage_layout';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';
import ProfileTabs from '../components/ProfileTabs/ProfileTabs';
import { navigate } from 'gatsby';
import { SystemStatusContext } from '../providers/SystemStatusProvider';

const ProfilePage = () => {
  const accessToken =
    typeof window !== 'undefined' && window.sessionStorage
      ? sessionStorage.getItem('accessToken')
      : '';

  const { maintenance } = useContext(SystemStatusContext);

  useEffect(() => {
    if (!accessToken) {
      if (maintenance) {
        navigate('/');
      } else {
        navigate('/bejelentkezes');
      }
    }
  }, [accessToken, maintenance]);

  if (!accessToken) return null;

  return (
    <SubPageLayout>
      <Head title="Profilom" />
      <BreadcrumbsLayout
        menuItems={[
          { name: 'Főoldal', path: '/' },
          { name: 'Profil', path: '/profil' },
        ]}
      >
        <ProfileTabs />
      </BreadcrumbsLayout>
    </SubPageLayout>
  );
};

export default ProfilePage;
