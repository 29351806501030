import React, { useEffect } from 'react';
// STYLE
import tableStyles from '../../HoldingsTable/HoldingsTable.module.scss';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
// UTILS
import moment from 'moment';
// HOOKS
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  FETCH_LOAN_HISTORY,
  FETCH_LOAN_HISTROY_COUNT,
  FETCH_REQUEST_HISTORY,
  FETCH_REQUEST_HISTORY_COUNT,
  FETCH_RESERVATION_HISTORY,
  FETCH_RESERVATION_HISTORY_COUNT,
} from './me';
import { CircularProgress, Typography } from '@material-ui/core';
import { translateRequestStatus, translateReservationStatus } from './util';
import Pagination from 'material-ui-flat-pagination';
import { useState } from 'react';

const ProfileTabsReservationsHistoryTab = props => {
  const [loanTableControl, setLoanTableControl] = useState({
    page: 0,
    take: 10,
    skip: 0,
  });
  const [reservationTableControl, setReservationTableControl] = useState({
    page: 0,
    take: 10,
    skip: 0,
  });
  const [requestTableControl, setRequestTableControl] = useState({
    page: 0,
    take: 10,
    skip: 0,
  });
  const { data: loanData, loading: loanDataLoading } = useQuery(
    FETCH_LOAN_HISTORY,
    {
      variables: {
        take: loanTableControl.take,
        skip: loanTableControl.skip,
      },
    }
  );
  const { data: loanCount } = useQuery(FETCH_LOAN_HISTROY_COUNT);

  const { data: reservationData, loading: reservationDataLoading } = useQuery(
    FETCH_RESERVATION_HISTORY,
    {
      variables: {
        take: reservationTableControl.take,
        skip: reservationTableControl.skip,
      },
    }
  );
  const { data: reservationCount } = useQuery(FETCH_RESERVATION_HISTORY_COUNT);

  const { data: requestData, loading: requestDataLoading } = useQuery(
    FETCH_REQUEST_HISTORY,
    {
      variables: {
        take: requestTableControl.take,
        skip: requestTableControl.skip,
      },
    }
  );
  const { data: requestCount } = useQuery(FETCH_REQUEST_HISTORY_COUNT);

  const onPageChange = (value, type) => {
    const action = prev => {
      const page = value / prev.take;

      return {
        ...prev,
        page,
        skip: prev.take * page,
      };
    };

    const actions = {
      loan: cb => {
        setLoanTableControl(cb);
      },
      reservation: cb => {
        setReservationTableControl(cb);
      },
      request: cb => {
        setRequestTableControl(cb);
      },
    };

    actions[type](action);
  };

  return (
    <Box className={[tableStyles.tableContainer].join(' ')}>
      <Typography variant="h6">Kölcsönzések</Typography>
      <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
        <Table
          stickyHeader
          className={[
            tableStyles.instanceListTable,
            tableStyles.openTable,
          ].join(' ')}
        >
          <TableHead>
            <TableRow>
              <TableCell>Cím</TableCell>
              <TableCell>Kölcsönzések ideje</TableCell>
              <TableCell>Esedékesség</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loanDataLoading && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
            {!loanDataLoading &&
              loanData?.me?.myLoansHistory?.map(node => (
                <TableRow key={node.guid || node.id}>
                  <TableCell>
                    {node?.item?.physicalManifestationOf?.title}
                  </TableCell>
                  <TableCell>
                    {moment(node?.loanDate).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {moment(node?.expiryDate).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          offset={loanTableControl.page * loanTableControl.take}
          limit={loanTableControl.take}
          total={loanCount?.me?.myLoansHistory_count}
          onClick={(e, offset) => onPageChange(offset, 'loan')}
        />
      </div>

      <Typography variant="h6">Foglalások</Typography>
      <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
        <Table
          stickyHeader
          className={[
            tableStyles.instanceListTable,
            tableStyles.openTable,
          ].join(' ')}
        >
          <TableHead>
            <TableRow>
              <TableCell>Cím</TableCell>
              <TableCell>Foglalás ideje</TableCell>
              <TableCell>Esedékesség</TableCell>
              <TableCell>Státusz</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationDataLoading && (
              <TableRow>
                <TableCell olSpan={5} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
            {!reservationDataLoading &&
              reservationData?.me?.myReservationsHistory?.map(node => (
                <TableRow key={node.guid || node.id}>
                  <TableCell>
                    {node?.targetItem?.physicalManifestationOf?.title}
                  </TableCell>
                  <TableCell>
                    {node?.expirationDate
                      ? moment(node?.expirationDate).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : null}
                  </TableCell>
                  <TableCell>
                    {node?.expectedDeliveryDate
                      ? moment(node?.expectedDeliveryDate).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : null}
                  </TableCell>
                  <TableCell>
                    {translateReservationStatus(node?.status)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          offset={reservationTableControl.skip}
          limit={reservationTableControl.take}
          total={reservationCount?.me?.myReservationsHistory_count}
          onClick={(e, offset) => onPageChange(offset, 'reservation')}
        />
      </div>

      <Typography variant="h6">Előjegyzések</Typography>
      <Box className={[tableStyles.instanceListTableContainer].join(' ')}>
        <Table
          stickyHeader
          className={[
            tableStyles.instanceListTable,
            tableStyles.openTable,
          ].join(' ')}
        >
          <TableHead>
            <TableRow>
              <TableCell>Cím</TableCell>
              <TableCell>Előjegyzések ideje</TableCell>
              <TableCell>Státusz</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestDataLoading && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
            {!requestDataLoading &&
              requestData?.me?.myRequestsHistory?.map(node => (
                <TableRow key={node.guid || node.id}>
                  <TableCell>
                    {node?.bibliographicRecord?.title ||
                      node?.item?.physicalManifestationOf?.title}
                  </TableCell>
                  <TableCell>
                    {moment(node?.requestDate).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>{translateRequestStatus(node?.status)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          offset={requestTableControl.skip}
          limit={requestTableControl.take}
          total={requestCount?.me?.myRequestsHistory_count}
          onClick={(e, offset) => onPageChange(offset, 'request')}
        />
      </div>
    </Box>
  );
};

export default ProfileTabsReservationsHistoryTab;
