import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import ProfileTabsActiveReservationsTabTable from './ProfileTabsActiveReservationsTabTable';

import { ME_QUERY } from './me';

const ProfileTabsActiveReservationsTab = props => {
  const [getActiveData, { data }] = useLazyQuery(ME_QUERY);

  useEffect(() => {
    getActiveData();
  }, []);

  const tableData = {
    myActiveLoans: [...(data?.me?.myActiveLoans || [])]
      .map(x => {
        let date = null;

        if (x?.loanDate) {
          date = new Date(x.loanDate);
        }

        return {
          ...x,
          dateForSort: date,
        };
      })
      .sort((a, b) => b.dateForSort - a.dateForSort),
    myActiveRequests: [...(data?.me?.myActiveRequests || [])]
      .map(x => {
        let date = null;

        if (x?.requestDate) {
          date = new Date(x.requestDate);
        }

        return {
          ...x,
          dateForSort: date,
        };
      })
      .sort((a, b) => b.dateForSort - a.dateForSort),
    myActiveReservations: [...(data?.me?.myActiveReservations || [])]
      .map(x => {
        let date = null;

        if (x?.createdAt) {
          date = new Date(x.createdAt);
        }

        return {
          ...x,
          dateForSort: date,
        };
      })
      .sort((a, b) => b.dateForSort - a.dateForSort),
  };

  return (
    <>
      <ProfileTabsActiveReservationsTabTable
        tableData={tableData}
        type={'myActiveLoans'}
        title={'Kölcsönzések'}
      />
      <ProfileTabsActiveReservationsTabTable
        tableData={tableData}
        type={'myActiveReservations'}
        title={'Foglalások'}
      />
      <ProfileTabsActiveReservationsTabTable
        tableData={tableData}
        type={'myActiveRequests'}
        title={'Előjegyzések'}
      />
    </>
  );
};

export default ProfileTabsActiveReservationsTab;
