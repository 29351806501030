import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextFieldInput = ({
  name,
  label,
  value,
  disabled = false,
  type = 'text',
}) => (
  <TextField
    disabled={disabled}
    value={value || ""}
    label={label}
    name={name}
    size="small"
    variant="outlined"
    fullWidth
    type={type}
    InputLabelProps={{ shrink: true }}
  />
);

export default TextFieldInput;
