import { useDialogControl } from "./useDialogControl"
import { useState } from "react";

export const useClaimRequestDialogControl = () => {
  const { open, openDialog, closeDialog } = useDialogControl();
  const [props, setProps] = useState(null);

  const openClaimRequestDialog = (data) => {
    setProps(data);
    openDialog();
  }

  return {
    openClaimRequestDialog,
    props: {
      open,
      closeDialog,
      ...props
    }
  }
}