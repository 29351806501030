import React, { useState } from 'react';
// STYLE
import styles from '../../pages/index.module.scss';
import Grid from '@material-ui/core/Grid';
// COMPONENTS
import ProfileTabsActiveReservationsTab from './ProfileTabsActiveReservationsTab/ProfileTabsActiveReservationsTab';
import ProfileTabsReservationsHistoryTab from './ProfileTabsReservationsHistoryTab/ProfileTabsReservationsHistoryTab';
import ProfileTabsReaderDataTab from './ProfileTabsReaderDataTab/ProfileTabsReaderDataTab';
import ProfileTabsFeesTab from './ProfileTabsFeesTab/ProfileTabsFeesTab';
import { controlledFeatures, deploymentCode } from '../../utils/featureControl';

const ProfileTabs = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(controlledFeatures.loan ? 0 : 1);

  const getActiveTabContent = () => {
    switch (activeTabIndex) {
      case 1:
        return <ProfileTabsReaderDataTab />;
      case 2:
        return <ProfileTabsFeesTab />;
      case 3:
        return <ProfileTabsReservationsHistoryTab />;
      default:
        return <ProfileTabsActiveReservationsTab />;
    }
  };

  const getStyleProp = (tabIndex) => ({
    style: {
      cursor: 'pointer',
      ...(activeTabIndex === tabIndex
        ? {
            color: '#e8b96b',
          }
        : null),
    },
  });

  return (
    <div className={styles.textContentContainer}>
      <Grid container spacing={4}>
        {controlledFeatures.loan && (
          <Grid
            item
            onClick={() => {
              setActiveTabIndex(0);
            }}
          >
            <h3 {...getStyleProp(0)}>Kölcsönzések/Foglalások/Előjegyzések</h3>
          </Grid>
        )}
        <Grid
          item
          onClick={() => {
            setActiveTabIndex(1);
          }}
        >
          <h3 {...getStyleProp(1)}>
            {deploymentCode === 'isxn' ? 'Felhasználói adatok' : 'Olvasói adatok'}
          </h3>
        </Grid>
        {
          // Később lehet kelleni fog erre egy új feature control változó
          controlledFeatures.loan && (
            <Grid
              item
              onClick={() => {
                setActiveTabIndex(2);
              }}
            >
              <h3 {...getStyleProp(2)}>Díjtételek</h3>
            </Grid>
          )
        }
        {controlledFeatures.loan && (
          <Grid
            item
            onClick={() => {
              setActiveTabIndex(3);
            }}
          >
            <h3 {...getStyleProp(3)}>Korábbi tranzakciók</h3>
          </Grid>
        )}
      </Grid>
      {getActiveTabContent()}
    </div>
  );
};

export default ProfileTabs;
