import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  query me($take: Int, $skipAct: Int, $skipHis: Int, $orderBy: [FeeOrderByWithRelationInput]) {
    me {
      guid
      myActiveFees(take: $take, skip: $skipAct, orderBy: $orderBy) {
        id
        createdAt
        type
        amount
        currentAmount
        status
        paidAt
        item {
          guid
          barcode
        }
      }
      myActiveFees_count
      myFeeHistory(take: $take, skip: $skipHis, orderBy: $orderBy) {
        id
        createdAt
        type
        amount
        currentAmount
        status
        paidAt
        invalidatedAt
        item {
          guid
          barcode
        }
      }
      myFeeHistory_count
    }
  }
`;