import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { EXTEND_LOAN } from '../components/ProfileTabs/ProfileTabsActiveReservationsTab/loan';

const okpErrors = [
  {
    code: 'OKP-1100',
    message: 'Olvasókártyája lejárt!',
  },
  {
    code: 'OKP-1101',
    message: 'A példány nem hosszabítható!',
  },
  {
    code: 'OKP-1102',
    message: 'Elérte a maximális hosszabítások számát!',
  },
  {
    code: 'OKP-1103',
    message: 'Az adott példányt valaki előjegyezte!',
  },
  {
    code: 'OKP-1104',
    message: 'A példány nem forgalmazható!',
  },
];

export const useExtendLoan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [extendLoanMutation] = useMutation(EXTEND_LOAN, {
    onCompleted() {
      enqueueSnackbar('Sikeres hosszabbítás!', { variant: 'success' });
      setLoading(false);
    },
    onError(error) {
      const code = error?.graphQLErrors?.[0]?.extensions?.code;
      const okpError =
        code && okpErrors.find(okpError => okpError.code === code);
      if (okpError) {
        enqueueSnackbar(okpError.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Sikertelen hosszabbítás!', { variant: 'error' });
      }
      setLoading(false);
    },
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY' });
      cache.gc();
    },
  });

  const extendLoan = loan => {
    if (loan?.id) {
      setLoading(true);
      extendLoanMutation({ variables: { where: { id: loan.id } } });
    }
  };

  return {
    extendLoan,
    loading,
  };
};
