export const resolveStatusTranslation = status => {
  const STATUS = {
    Pending: 'Folyamatban',
    Available: 'Elérhető',
    AwaitingResponse: 'Válaszra vár',
    Canceled: 'Visszavont',
    Expired: 'Lejárt',
    Completed: 'Teljesített',
    Failed: 'Sikertelen',
    Loaned: 'Kikölcsönözve',
    Returned: 'Visszavitt',
  };

  return STATUS?.[status];
};

export const resolveStatusColumn = status => {
  if (typeof status === 'boolean') {
    if (status) {
      return resolveStatusTranslation('Loaned');
    } else {
      return resolveStatusTranslation('Returned');
    }
  }

  return resolveStatusTranslation(status);
};

export const resolveTransactionTypeTranslation = type => {
  const TRANSACION_TYPE = {
    Request: 'Előjegyzés',
    Loan: 'Kölcsönzés',
    Reservation: 'Foglalás',
  };

  return TRANSACION_TYPE?.[type];
};
